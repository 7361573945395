
import { Component, Vue, Prop } from 'vue-property-decorator'
import ApplicationSelector, { ApplicationDataItem } from '@/components/common-select/application-selector.vue'
import AppInstanceSelector, { AppInstanceDataItem } from '@/components/common-select/app-instance-selector.vue'

export interface AppInstanceItem {
  application: ApplicationDataItem;
  appInstance: AppInstanceDataItem;
  // 是否折叠
  fold: boolean;
  [propName: string]: any;

  option: Record<string, any>;
}

@Component({
  name: 'ApplicationInstanceList',
  components: {
    AppInstanceSelector,
    ApplicationSelector
  }
})
export default class extends Vue {
  /// 当前选中的应用
  currentApplication: ApplicationDataItem = { appId: -1 }
  /// 当前选中的实例
  currentInstance: AppInstanceDataItem = { instanceId: -1 }
  /// 列表
  list: Array<AppInstanceItem> = []

  /// 提供给item的额外参数
  @Prop({
    type: Object,
    default: () => ({})
  })
  option!: Record<string, any>

  @Prop({
    type: String,
    required: true
  })
  sessionKey!: string

  /// 打开页面时加载上一次的实例
  created () {
    this.loadAppInstanceFromSession()
    this.$emit('addItems', this.list)
  }

  /**
   * 删除一个节点
   * @param index
   */
  removeAppInstanceItem (index: number) {
    const items = this.list.splice(index, 1)
    this.saveAppInstanceToSession()
    this.$emit('rmItems', items)
  }

  addAppInstanceItem () {
    if (this.currentInstance === undefined || this.currentInstance.instanceId < 1) {
      this.$Notice.error({
        title: '添加失败',
        desc: '请选择应用和实例'
      })
      return
    }
    // 不允许重复添加实例
    if (this.list.some((item) => item.appInstance.instanceId === this.currentInstance.instanceId)) {
      this.$Notice.error({
        title: '添加失败',
        desc: '实例已经在监控列表中了，请不要重复添加'
      })
      return
    }

    const item = {
      application: this.currentApplication,
      appInstance: this.currentInstance,
      fold: false,
      option: this.option
    }

    this.list.push(item)
    this.$emit('addItems', [item])
    this.saveAppInstanceToSession()
  }

  /// 保存实例数据
  saveAppInstanceToSession () {
    const data = JSON.stringify(this.list)
    sessionStorage.setItem(this.sessionKey, data)
  }

  /// 加载实例数据
  loadAppInstanceFromSession () {
    const data = sessionStorage.getItem(this.sessionKey)
    if (!data || data === '') {
      this.list = []
    } else {
      this.list = JSON.parse(data)
    }
  }

  /**
   * 展开切换
   * @param index
   */
  toggleInstance (index: number) {
    this.list[index].fold = !this.list[index].fold
  }
}
