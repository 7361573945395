
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import ApplicationApi, { Application } from '@/api/maintain/application'

/**
 * 应用项目数据
 */
export interface ApplicationDataItem{
  // 应用Id
  appId: number;
  // 应用名称
  appName?: string;
}

// 团队选择组件
@Component({ name: 'ApplicationSelector', components: {}, mixins: [] })
export default class ApplicationSelector extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: undefined
  })
  value?: ApplicationDataItem

  @Prop({
    type: String,
    default: '输入应用名称'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  @Prop({
    type: String,
    default: 'default'
  })
  size!: 'small' | 'default' | 'large'

  loading = false

  /// 选择项目
  items: Array<ApplicationDataItem> = []

  /// 当前选择值 计算属性
  get curValue (): number {
    return this.value !== undefined ? this.value.appId : -1
  }

  onChange (newValue: number) {
    const curValue = this.items.find((item) => item.appId === newValue)
    this.$emit('input', curValue)
  }

  created () {
    this.loadDataFromServer()
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    const queryData = {
      page: 1,
      pageSize: 100,
      sorts: []
    }
    ApplicationApi.getCurTeamApplicationListExceptWeb()
      .then((response) => {
        const data = response.data
        this.items = data!.map((e) => {
          const item: ApplicationDataItem = {
            appId: e.id!,
            appName: e.name!
          }
          return item
        })
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '加载应用下拉框失败:' + err.message
        })
      }).finally(() => { this.loading = false })
  }
}
