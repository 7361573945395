
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'

@Component({
  name: 'tableListPanel'
})
export default class TableListPanel extends Vue {
    /**
     * 页签名的名称
     */
    @Prop({
      type: String,
      default: ''
    })
    title!: string

    /**
     * 页签是否当前可见
     */
    @Prop({
      type: Boolean,
      default: false
    })
    visiable!: boolean

    curVisiable=false

    // 监控修改状态
    @Watch('visiable')
    private onWatchVisiable (visiable: boolean) {
      this.curVisiable = visiable
    }

    // 显示指令
    public show (visiable: boolean) {
      this.curVisiable = visiable
    }

    created () {
      this.onWatchVisiable(this.visiable)
    }
}
