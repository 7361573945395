
import { Vue, Component } from 'vue-property-decorator'
import TablePanel from './tablist-panel.vue'

/**
 * tab项
 */
interface TabItem{
  /// 标题名称
  titile: string;
  /// 是否选中
  active: boolean;
  /// 页版对象
  panel: any;
}

@Component({
  name: 'tabList'
})
export default class TabList extends Vue {
  items: Array<TabItem>=[]

  mounted () {
    this.$nextTick(() => {
      const paneles = this.$children as Array<TablePanel>
      this.items = paneles.map(item => {
        const re: TabItem = {
          titile: item.title,
          active: item.visiable,
          panel: item
        }
        return re
      })
      if (!this.items) this.items = []
    })
  }

  activePanel (index: number) {
    const item = this.items[index]
    item.active = true
    item.panel.show(true)

    this.items.filter((theItem, theIndex) => theIndex !== index).forEach(theItem => {
      theItem.active = false
      theItem.panel.show(false)
    })
  }
}
