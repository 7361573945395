
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import AppInstanceApi, { AppInstance } from '@/api/maintain/app-instance'

/**
 * 应用实例数据项
 */
export interface AppInstanceDataItem{
  /// 实例id
  instanceId: number;
  /// 实例名称
  instanceName?: string;
  /// 服务器ip
  ipAddress?: string;
  /// 容器名称
  containerName?: string;
}

// 团队选择组件
@Component({ name: 'AppInstanceSelector', components: {}, mixins: [] })
export default class AppInstanceSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input

  @Prop({
    type: Object,
    required: false
  })
  value?: AppInstanceDataItem

  /// 当前选择的值
  get curValue () {
    return this.value !== undefined ? this.value.instanceId : null
  }

  @Prop({
    type: Number,
    default: undefined
  })
  applicationId!: number;

  @Prop({
    type: String,
    default: '输入实例名称'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  @Prop({
    type: String,
    default: 'default'
  })
  size!: 'small' | 'default' | 'large'

  loading = false

  items: Array<AppInstanceDataItem> = []

  /// 选择事件
  onChange (newValue: number) {
    const item = this.items.find((item) => item.instanceId === newValue)
    this.$emit('input', item)
  }

  created () {
    this.loadDataFromServer()
  }

  @Watch('applicationId')
  watchDependId (newValue: number) {
    if (newValue) {
      this.loadDataFromServer()
    }
    this.$emit('input', undefined)
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true

    if (this.applicationId > 0) {
      AppInstanceApi.listAppInstanceByAppId(this.applicationId)
        .then((response) => {
          const data = response.data!
          this.items = data!.map((e) => {
            const res: AppInstanceDataItem = {
              instanceId: e.id!,
              instanceName: e.name!,
              ipAddress: e.pcServerIpAddress,
              containerName: e.containerName
            }
            return res
          })
        })
        .catch((err) => {
          this.$Notice.error({
            title: '错误',
            desc: '加载应用下拉框失败:' + err.message
          })
        }).finally(() => { this.loading = false })
    } else {
      this.items = []
      this.loading = false
    }
  }
}
