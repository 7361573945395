
import { Component, Vue } from 'vue-property-decorator'
import AppInstanceList, { AppInstanceItem } from '@/components/app-instance-list.vue'
import terminalConfig from '../log-console/terminal.config'
import TerminalVue, { XtermVueInterface } from '@/components/xterm-vue/XtermVue.vue'
import { EventSourcePolyfill } from 'event-source-polyfill'
import appInstanceManger from '@/api/maintain/app-instance-manger'
import { TaskMessage } from '@/libs/podman-request'

interface InstanceData{
  hasError: boolean;
  term?: XtermVueInterface;
  flux?: EventSourcePolyfill;
}

@Component({
  name: 'LoggerHistory',
  components: { AppInstanceList, TerminalVue }
})
export default class LoggerRealTime extends Vue {
  instanceDatas: Record<number, InstanceData>={}

  /// 终端配置
  terminalOption = terminalConfig.terminalOption;

  /// 加载行数选项
  loadRowsOption = [10, 20, 50, 100, 200, 500];

  /**
   * 添加监控实例
   * @param item
   */
  addItem (items: AppInstanceItem[]) {
    for (const item of items) {
      this.instanceDatas[item.appInstance.instanceId] = {
        hasError: true
      }
    }
  }

  /**
   * 删除监控实例
   * @param item
   */
  rmItem (items: AppInstanceItem[]) {
    for (const item of items) {
      const instanceData = this.instanceDatas[item.appInstance.instanceId]
      if (instanceData.flux) instanceData.flux.close()
      this.instanceDatas[item.appInstance.instanceId] = undefined as any
    }
  }

  /**
   * 终端初始化完成
   */
  onTerminalReady (item: AppInstanceItem) {
    console.log('item.option', item.option)
    const appInstanceId = item.appInstance.instanceId
    const instanceData = this.instanceDatas[appInstanceId]
    instanceData.term = (this.$refs.term as any) as XtermVueInterface
    instanceData.term.clear() // 先清除旧的控制台输出
    const queryData = {
      instanceId: appInstanceId,
      ...item.option
    }

    // 请求实例的日志
    appInstanceManger.getHistroyLogData(queryData, {
      onOpen: () => {
        instanceData.term?.addLineMessage('联结上应用实例')
        instanceData.hasError = false
      },
      onError: (err: Error) => {
        instanceData.term?.addLineMessage('发生错误:' + err.message)
        instanceData.hasError = true
      },
      onMessage: (message: TaskMessage) => {
        instanceData.term?.addLineMessage(message.message)
      }
    })
      .then((flux) => { instanceData.flux = flux })
      .catch((err) => {
        this.$Message.error('监控实例日志失败:' + err.message)
        instanceData.hasError = true
      })
  }

  /**
   * 重新联结
   */
  reConnect (item: AppInstanceItem) {
    console.log('reConnect,item=>', item)
    const appInstanceId = item.appInstance.instanceId
    const instanceData = this.instanceDatas[appInstanceId]
    if (instanceData.flux) {
      instanceData.flux.close()
    }
    this.onTerminalReady(item)
  }

  /**
   * 释放资源
   */
  destroyed () {
    for (const key in this.instanceDatas) {
      const instanceData = this.instanceDatas[key]
      if (instanceData.flux) instanceData.flux.close()
    }
  }

  /**
   * 选择某个日志的开始时间
   */
  onItemStartTimeChange (value: any, item: AppInstanceItem) {
    item.option.startTime = value
    item.option.endTime = ''
    item.option.showRows = 0
    console.log('onItemStartTimeChange,item.option', item.option)
  }

  /**
   * 选择某个日志的结束时间
   */
  onItemEndTimeChange (value: any, item: AppInstanceItem) {
    item.option.endTime = value
    item.option.startTime = ''
    item.option.showRows = 0
    console.log('onItemEndTimeChange,item.option', item.option)
  }

  /**
   * 选择某个日志的行数
   */
  onItemTailSelect (value: any, item: AppInstanceItem) {
    item.option.showRows = value
    item.option.startTime = ''
    item.option.endTime = ''
    console.log('onItemTailSelect,item.option', item.option)
  }
}
