
import { Component, Vue } from 'vue-property-decorator'
import TabList from '@/components/tab-list.vue'
import TableListPanel from '@/components/tablist-panel.vue'
import LoggerRealTime from './logger-real-time/index.vue'
import LoggerHistory from './logger-history/index.vue'
import 'xterm/css/xterm.css'

@Component({
  name: 'LogerCenter',
  components: { TabList, TableListPanel, LoggerRealTime, LoggerHistory }
})
export default class LogerCenter extends Vue {}
